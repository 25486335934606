.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.z-60 {
  z-index: 60;
}

.z-70 {
  z-index: 70;
}
