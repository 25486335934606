/* Hide scrollbars for WebKit browsers (Chrome, Safari) */
.seven-day-calendar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbars for Firefox */
.seven-day-calendar {
  scrollbar-width: none; /* Firefox: hide scrollbar */
  -ms-overflow-style: none; /* IE/Edge: hide scrollbar */
}

/* Customize scrollbars for the calendar container */
.seven-day-calendar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(203, 213, 225, 0.4) transparent; /* For Firefox */
}

/* For Webkit browsers (Chrome, Safari, etc.) */
.seven-day-calendar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.seven-day-calendar::-webkit-scrollbar-track {
  background: transparent;
}

.seven-day-calendar::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.4);
  border-radius: 20px;
}

.seven-day-calendar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(203, 213, 225, 0.6);
}

/* Customize scrollbars for individual appointments */
.seven-day-calendar .group {
  scrollbar-width: thin;
  scrollbar-color: rgba(203, 213, 225, 0.4) transparent;
}

.seven-day-calendar .group::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.seven-day-calendar .group::-webkit-scrollbar-track {
  background: transparent;
}

.seven-day-calendar .group::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.4);
  border-radius: 20px;
}

.seven-day-calendar .group::-webkit-scrollbar-thumb:hover {
  background-color: rgba(203, 213, 225, 0.6);
}
