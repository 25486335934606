/* tooltip.css */

/* Tooltip container */
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  min-width: 150px; /* Increased width */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px; /* Increased padding */
  position: absolute;
  z-index: 1;
  bottom: 150%; /* Position the tooltip above the text */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
